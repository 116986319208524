import React, { useEffect } from "react";
import digital from "../Assets/Icons/digital.svg";
import web1 from "../Assets/Icons/web1.svg";
import web2 from "../Assets/Icons/web2.svg";
import web3 from "../Assets/Icons/web3.svg";
import web4 from "../Assets/Icons/web4.svg";
import digital1 from "../Assets/Icons/digital1.svg";
import digital2 from "../Assets/Icons/digital2.svg";
import digital3 from "../Assets/Icons/digital3.svg";
import branding1 from "../Assets/Icons/branding1.svg";
import branding2 from "../Assets/Icons/branding2.svg";

import "../Styles/Services.css";

const Services = () => {
  const servicesLink = document.querySelector(".services-link");

  return (
    <div id="services" className="services-box">
      {/* <div id="Awesome" class="anim750">
        <div class="reveal circle_wrapper">
          <div class="circle">Hello!</div>
        </div>
        <div class="sticky anim750">
          <div class="front circle_wrapper anim750">
            <div class="circle anim750"></div>
          </div>
        </div>
        <h4>Peel Me!</h4>
        <div class="sticky anim750">
          <div class="back circle_wrapper anim750">
            <div class="circle anim750"></div>
          </div>
        </div>
      </div> */}

      {/* <div className="section web-box anim750" id="anim750">
        <div class="reveal circle_wrapper">
          <div class="circle">Content!</div>
        </div>


        <div class="sticky anim750">
          <div class="front circle_wrapper anim750">
            <div class="circle anim750"></div>
          </div>
        </div>


        <h1 className="serv-font">WEB DESIGN</h1>
        <div class="sticky anim750">
          <div class="back circle_wrapper anim750">
            <div class="circle anim750"></div>
          </div>
        </div>
        
      </div> */}

      <div className="section web-box">
        <h1 className="serv-font">WEB DESIGN</h1>
        <div className="web-img">
          <img className="" src={web1}></img>
          <img src={web2}></img>
          <img className="mt-[-11%]" src={web3}></img>
          <img className="" src={web4}></img>
        </div>
        <div className="web-content-container">
        <div className="web-content">
          Our designs not only look stunning, but also function in the most
          intuitive and simplistic manner. We conduct extensive research and
          create solutions that make your customer's journey meaningful with
          every click and impression.
        </div>
        </div>
        <a href="/contactus" className="learn-btn">
          Learn more
        </a>
      </div>

      <div className="section digital-box">
        <h1 className="serv-font">DIGITAL MARKETING</h1>
        <div className="digital-img">
          <img src={digital1}></img>
          <img src={digital2}></img>
          <img className="digital3" src={digital3}></img>
        </div>
        <div className="digital-content">
          We deliver creatives & Ads that turn heads, spark conversations and
          emotionally connects with your audience. Our digital experts have a
          combined experience of over 20 years crafting campaigns, ads and
          social media content that drives higher engagement and conversion.
        </div>
        <a href="/contactus" className="learn-btn">
          Learn more
        </a>
      </div>

      <div className=" branding-box">
        <h1 className="serv-font">BRANDING</h1>
        <div className="branding-img">
          <img src={branding1}></img>
          <img src={branding2}></img>
        </div>
        <div className="branding-content">
          The most crucial aspect of any business, small or large, it all starts
          with a Brand. We create strategies that acts as the North Star leading
          to the most effective communication. From logo designs, to visuals &
          animations, to an engaging Brand voice, we help you with it all.
        </div>
        <a href="/contactus" className="learn-btn">
          Learn more
        </a>
      </div>

      {/* <div className="started">
        <a href="/contactus" className="started-btn">
          Let’s get started!
        </a>
      </div> */}
    </div>
  );
};

export default Services;

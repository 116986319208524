import React from "react";
import Contact from "../Components/Contact";

export default function HomePage() {
  return (
    <div>
      <Contact />
    </div>
  );
}

import React from "react";
import bookmark from "../Assets/Icons/bookmark.svg";

import "../Styles/AboutUs.css";

const AboutUs = () => {
  return (
    <div id="philosophy" className="about-main">
      <div className="about-box1">
        <p>
          Already know what you want ? <br />
          Reach out, and let’s make it happen.
        </p>

      </div>

      <a href="/contactus" className="about-btn">
        Start your journey
      </a>

      <div className="about-box2">
        <p className="box2-font">
          We design & create solutions with your needs <br /> at the center of
          our 3 point philosophy
        </p>

        <div className="card-box">
          <div class="card">
            <div className="style-text">
              <p className="card-text">
                We create and develop simplistic yet stunning design that grabs
                attention and elevates your brand. Less is sometimes More.
              </p>
            </div>

            <div class="cover">
            <div>
                  <img className="bookmark-icon" src={bookmark}></img>
             </div>
              <div class="coverFront">
                <div>
                  <h5 className="card-title">SIMPLE</h5>
                </div>
              </div>
              <div class="coverBack"></div>
            </div>
          </div>

          <div className="mobile-style">
          <div class="card">
            <div className="style-text">
              <p className="card-text">
                As a creative agency at our core, we craft stunning user
                experience that is intuitive and focused on your audience.
              </p>
            </div>
            <div class="cover">
            <div>
                  <img className="bookmark-icon" src={bookmark}></img>
             </div>
              <div class="coverFront">
                <div>
                  <h5 className="card-title">ELEGANT</h5>
                </div>
              </div>
              <div class="coverBack"></div>
            </div>
          </div>

          <div class="card">
            <div className="style-text">
              <p className="card-text">
                Our tailor made solutions helps you drive better engagement with
                your customers leading to higher conversions.
              </p>
            </div>
            <div class="cover">
            <div>
                  <img className="bookmark-icon" src={bookmark}></img>
             </div>
              <div class="coverFront">
                <div>
                  <h5 className="card-title">MARKETABLE</h5>
                </div>
              </div>
              <div class="coverBack"></div>
            </div>
          </div>
        </div>

        </div>


        {/* <div className="card-box">
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <h1 className="card-title">SIMPLE</h1>
              </div>
              <div class="flip-card-back">
                <p className="card-text">
                  We create and develop simplistic yet stunning design that
                  grabs attention and elevates your brand. Less is sometimes
                  More.
                </p>
              </div>
            </div>
          </div>

          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <h1 className="card-title">ELEGANT</h1>
              </div>
              <div class="flip-card-back">
                <p className="card-text">
                  As a creative agency at our core, we craft stunning user
                  experience that is intuitive and focused on your audience.
                </p>
              </div>
            </div>
          </div>

          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <h1 className="card-title">MARKETABLE</h1>
              </div>
              <div class="flip-card-back">
                <p className="card-text">
                  Our tailor made solutions helps you drive better engagement
                  with your customers leading to higher conversions.
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AboutUs;

import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Header from "./Shared/Header";
import logo from "../Assets/Icons/logo.png";
// import contactimg from "../Assets/Img/contact-img.svg";
import contactlogo from "../Assets/Icons/logo.png";
import linkedin from "../Assets/Icons/linkedin.svg";
import insta from "../Assets/Icons/insta.svg";
import phone from "../Assets/Icons/phone.svg";
import emailicon from "../Assets/Icons/email.svg";
import location from  "../Assets/Icons/location.svg";
import backarrow from "../Assets/Icons/backarrow.svg"

import "../Styles/Contact.css";

       const Contact = () => {
  const navigate = useNavigate();
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    // navigate("/");

    emailjs
    // .sendForm("service_zsh2a5f", "template_1jv842i", form.current, {
    //   publicKey: "ItVSVh5n271K1E219",
    // })
      .sendForm("service_rjnzoj6", "template_egyjrvd", form.current, {
        publicKey: "dLGaSfNHHKUpDuLYk",
      }) 
      .then(
        () => {
          console.log("SUCCESS!");
          alert('Successfully submitted!');
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("An error occurred while sending the message.");
        }
      );
  };

  return (
    <div className="contact-container">
      <div className="form-container">
      <a href="/">
          <img className="contact-logo" src={contactlogo}></img>
          </a>
      <a href="/">

        <img className="back-arrow" src={backarrow}></img>
        </a>
        <div className="contact-content">
          <div className="info-section">
          <ul class="phone-info mt-[3rem] ml-[1rem] space-y-10">
        <h2 class="img-title">New Business</h2>

            <li class="flex items-center max-lg:justify-center">
              <img className="phone-icon" src={phone}></img>
              <a href="javascript:void(0)" class="info-text ml-3">
                <p>(+91) 7990527375</p>
              </a>
            </li>
            <li class="flex items-center max-lg:justify-center">
            <img className="email-icon" src={emailicon}></img>
              <a href="javascript:void(0)" class="info-text ml-3">
                <p>consulting@whiteboxdata.com</p>
              </a>
            </li>
            <li class="flex items-center max-lg:justify-center">
            <img className="location-icon" src={location}></img>
              <a href="javascript:void(0)" class="info-text ml-3">
                <p>518 Swati Clover, SP Ring Road, Thaltej, Ahmedabad 380059</p>
              </a>
            </li>
            <div  className="contact-icons">
            
          
            <a href="https://www.instagram.com/whiteboxdata/">
              <img className="insta" src={insta}></img>
            </a>
            <a href="https://www.linkedin.com/company/white-box-data/">
              <img className="linkedin" src={linkedin}></img>
            </a>
            </div>
          </ul>
          </div>

          <div class="vertical-line"></div>
          
          <div className="form-section">
          <p className="form-title">Just write us a message!<br/> We'll be glad to assist you!</p>

          <form ref={form} onSubmit={sendEmail}>

                  <div class="grid sm:grid-cols-2 gap-10">
                    <div class="relative flex items-center">
                      <input
                        type="text"
                        placeholder="First name*"
                        name="first_name"
                        value={firstname}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="form-text px-0 py-4 w-full outline-none"
                        required
                      />
                      <span class="error" aria-live="polite"></span>
                      
                    </div>
                    <div class="relative flex items-center">
                      <input
                        type="text"
                        placeholder="Last name*"
                        name="last_name"
                        value={lastname}
                        onChange={(e) => setLastName(e.target.value)}
                        class="form-text px-0 py-4 w-full outline-none"
                        required
                      />
                      <span class="error" aria-live="polite"></span>
                     
                    </div>
                    <div class="relative flex items-center">
                      <input
                        type="number"
                        placeholder="Mobile*"
                        value={mobile}
                        name="from_mobile"
                        onChange={(e) => setMobile(e.target.value)}
                        class="form-text py-4 w-full outline-none"
                        required
                        minLength="10"
                        maxLength="10"
                        // inputmode="numeric"
                        
                      />
                      <span class="error" aria-live="polite"></span>
                    </div>
                    <div class="relative flex items-center">
                      <input
                        type="email"
                        placeholder="Email*"
                        name="from_email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        class="form-text py-4 w-full outline-none"
                        required
                      />
                      <span class="error" aria-live="polite"></span>
                    </div>
                    <div class="relative flex items-center sm:col-span-2 ml-[-2.1%]">
                      <textarea
                        placeholder="Write Message"
                        class="form-text py-2 w-full outline-none"
                        value={message}
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      
                    </div>
                    
                  </div>
          <button
            type="submit"
            class="contact-btn max-lg:w-full"
          >
            Send Message
          </button>

          
        </form>
          </div>
       

        
        </div>

     


      </div>
    </div>
  );
};

export default Contact;

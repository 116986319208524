import React from "react";
import DigitalMarketing from "../Components/DigitalMarketing";

export default function DigitalMarketingPage() {
  return (
    <div>
      <DigitalMarketing />
    </div>
  );
}

import React from "react";
import WebDesign from "../Components/WebDesign";

export default function WebDesignPage() {
  return (
    <div>
      <WebDesign />
    </div>
  );
}

import React from "react";
import FAQs from "../Components/FAQs";

export default function FAQsPage() {
  return (
    <div>
      <FAQs />
    </div>
  );
}

import React, { useState } from "react";

import "../../Styles/Header.css";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLinkClick = (event) => {
    if (event.target.tagName === "A") {
      const href = event.target.getAttribute("href");
      if (href && href !== "#") {
        setIsNavOpen(false);
        setTimeout(() => {
          window.location.href = href;
        }, 0);
        event.preventDefault();
      }
    }
  };

  return (
    <div className="">
      <input
        type="checkbox"
        id="main-navigation-toggle"
        className="btn btn--close"
        title="Toggle main navigation"
        checked={isNavOpen}
        onChange={toggleNav}
      />
      <label htmlFor="main-navigation-toggle">
        <div>
          <span className="circle"></span>
        </div>
      </label>

      <nav
        id="main-navigation"
        className={`nav-main ${isNavOpen ? "nav-main--open" : ""}`}
      >
        <ul className="menu" onClick={handleLinkClick}>
          <li className="menu__item">
            <a className="menu__link" href="/">
              HOME
            </a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="#services">
              SERVICES
            </a>
            {/* <ul class="submenu">
              <li class="menu__item">
                <a class="menu__link" href="#0">
                  Print Design
                </a>
              </li>
              <li class="menu__item">
                <a class="menu__link" href="#0">
                  Web Design
                </a>
              </li>
              <li class="menu__item">
                <a class="menu__link" href="#0">
                  Mobile App Development
                </a>
              </li>
            </ul> */}
          </li>
          <li className="menu__item">
            <a className="menu__link" href="#philosophy">
              OUR PHILOSOPHY
            </a>
          </li>

          <li className="menu__item">
            <a className="menu__link" href="/contactus">
              CONTACT
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;

import React from "react";
import Branding from "../Components/Branding";

export default function BrandingPage() {
  return (
    <div>
      <Branding />
    </div>
  );
}

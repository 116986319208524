import React, { useEffect } from "react";
import Header from "../Components/Shared/Header";
import Footer from "../Components/Shared/Footer";
import Services from "./Services";
// import BottomNav from "./BottomNav";
import ParticlesComponent from "./Particle";
import arrow from "../Assets/Icons/arrow.svg";
import AboutUs from "./AboutUs";

import logo from "../Assets/Icons/logo.png";
import "../Styles/Home.css";

function Home() {

  return (
    <>
      <ParticlesComponent id="particles" className="z-0" />

      <div className="home-img">
        <div className=" logo-header">
        <a href="/">
          <img className="logo" src={logo}></img>
        </a>
        <Header />
        </div>

        <div className="home-box space-y-4">
          <h1 className="home-font">
            Simple, <span className="elegant-font">Elegant</span> & Marketable
          </h1>
          <div className="horizontal-line"></div>

          <p className="title-para">
            Stories we tell, ideas we envision and designs we craft must create
            a lasting Brand Impression. We are a new age Marketing, Branding and
            Design agency. We are on a mission to simplify your digital journey.
          </p>

          <a href="/contactus" className="home-btn">
            Get in touch
          </a>

          <a href="#services">
          <img className="img-arrow" src={arrow}></img>
          </a>

        {/* <div className="arrow-div">
          <a className="img-arrow" href="#services">
            <div class="arrow arrow-first"></div>
          <div class="arrow arrow-second"></div>
          </a>
        </div> */}

        </div>
        
      </div>

      <Services />

      <AboutUs />

      <Footer />
    </>
  );
}

export default Home;

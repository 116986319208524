import React from "react";
import PrivacyPolicy from "../Components/PrivacyPolicy";

export default function PrivacyPolicyPage() {
  return (
    <div>
      <PrivacyPolicy />
    </div>
  );
}

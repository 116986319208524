import React from "react";
import { createBrowserRouter } from "react-router-dom";

import HomePage from "./Pages/HomePage";
import ContactPage from "./Pages/ContactPage";
import FAQsPage from "./Pages/FAQsPage";
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage";
import WebDesignPage from "./Pages/WebDesignPage";
import DigitalMarketingPage from "./Pages/DigitalMarketingPage";
import BrandingPage from "./Pages/BrandingPage";


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },

  {
    path: "/contactus",
    element: <ContactPage />,
  },

  {
    path: "/faqs",
    element: <FAQsPage />,
  },

  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },

  {
    path: "/web-design",
    element: <WebDesignPage />,
  },

  {
    path: "/digital-marketing",
    element: <DigitalMarketingPage />,
  },

  {
    path: "/branding",
    element: <BrandingPage />,
  },

  

]);

export default router;

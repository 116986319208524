import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Icons/logo.png";
import linkedin from "../../Assets/Icons/linkedin.svg";
import insta from "../../Assets/Icons/insta.svg";
import emailjs from "@emailjs/browser";
import footer from "../../Assets/Img/desktop-footer.jpg"

import "../../Styles/Footer.css";

export const Footer = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const form = useRef();
  // const [isSubmitted, setIsSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    navigate("/");

    emailjs
      .sendForm("service_zsh2a5f", "template_ee3sf7k", form.current, {
        publicKey: "ItVSVh5n271K1E219",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          alert('You have signed up!');
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("An error occurred while sending the message.");
        }
      );
  };

  return (
    <div className="footer-main footer-img">
      {/* <img class="footer-img" src={footer}></img> */}
      
      <div className="footer-content">
        
        <img className="footer-logo" src={logo}></img>
        <h1>
          Subscribe to get information, latest news and other interesting offers
          about{" "}
        </h1>
        <form ref={form} onSubmit={sendEmail}>
          <div className="email-btn">
            <input
              className="email-box"
              type="email"
              id="email"
              placeholder="Your email"
              name="from_email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            {/* {isSubmitted ? ( // Conditionally render alert message
              <span className="signup-alert">You've signed up!</span>
            ) : ( */}
              <a href="/">
                <button className="footer-btn">Sign up today</button>
              </a>
            {/* )} */}

            {/* <a href="/">
              <button className="footer-btn">Sign up today</button>
            </a> */}
          </div>
        </form>

        <div className="icons">
          <a href="https://www.instagram.com/whiteboxdata/">
            <img className="insta" src={insta}></img>
          </a>
          <a href="https://www.linkedin.com/company/white-box-data/">
            <img className="linkedin" src={linkedin}></img>
          </a>
          <p className="mail-text">info@whiteboxdata.com</p>
        </div>

        <div className="links">
        <a href="/contactus">
          <span>Contact | </span>
        </a>
        <a href="/faqs">
          <span>FAQs | </span>
        </a>
        <a href="/privacy-policy">
          <span>Privacy Policy</span>
        </a>
      </div>
      </div>

      
  
    </div>
  );
};

export default Footer;
